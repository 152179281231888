@media screen and (max-width: 480px){
	#grid[data-columns]::before {
  content: '1 .column.size-1of1';
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
	#grid[data-columns]::before {
		content: '2 .column.size-1of2';
	}
}

@media screen and (min-width: 769px) {
	#grid[data-columns]::before {
		content: '3 .column.size-1of3';
	}
}

/* These are the classes that are going to be applied: */
.column { float: left; }
.size-1of1 { width: 100%; }
.size-1of2 {  width: 50%; }
.size-1of3 {  width: 33.333%; }
.item { padding: .5em; }
