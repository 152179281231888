@charset "UTF-8";

// Colors
@import "color";

// Variables;
@import "variables";
@import "main-variables";

// Reset
@import "normalize";

// import custom css
@import "main";
@import "main-dark";
@import "cookiepopup";

// components
@import "salvattore";
@import "global";
@import "badges";
@import "icons-material-design";
@import "grid";
@import "navbar";
@import "roboto";
@import "typography";
@import "transitions";
@import "cards";
@import "toast";
@import "tabs";
@import "tooltip";
@import "buttons";
@import "dropdown";
@import "waves";
@import "modal";
@import "collapsible";
@import "chips";
@import "materialbox";
@import "forms/forms";
@import "table_of_contents";
@import "sideNav";
@import "preloader";
@import "slider";
@import "carousel";
@import "tapTarget";
@import "pulse";
@import "date_picker/default";
@import "date_picker/default.date";
@import "date_picker/default.time";
